import {
  FORM_CLASS_FOCUS,
  FORM_CLASS_INPUT_BG_DISABLED,
  FORM_CLASS_INPUT_BG_ENABLED,
  FORM_CLASS_INPUT_TEXT_DISABLED,
  FORM_CLASS_INPUT_TEXT_ENABLED,
  FORM_CLASS_INVALID,
} from './common';
import classNames from 'classnames';

export type InputSelectOption = {
  disabled?: boolean;
} & (
  | {
      value: string;
      label: string;
    }
  | { id: string | number; name: string }
);

export type InputSelectProps = {
  value?: string | null;
  valid?: boolean;
  name?: string;
  disabled?: boolean;
  focus?: boolean;
  options: InputSelectOption[];
  placeholder?: string;
  onChange?: (value: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
  readonly?: boolean;
};

export default function InputSelect(props: InputSelectProps) {
  const options = props.options.map((o) => ('id' in o ? { value: o.id.toString(), label: o.name } : o));

  return (
    <>
      <select
        value={props.value || ''}
        className={classNames(
          'text-base w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md block focus:outline-none sm:text-sm select-none',
          {
            [FORM_CLASS_INPUT_BG_DISABLED]: props.disabled,
            [FORM_CLASS_INPUT_BG_ENABLED]: !props.disabled,
            [FORM_CLASS_INPUT_TEXT_DISABLED]: props.disabled,
            [FORM_CLASS_INPUT_TEXT_ENABLED]: !props.disabled,
            [FORM_CLASS_FOCUS]: !props.disabled,
            hidden: props.readonly,
            [FORM_CLASS_INVALID]: props.valid === false,
            'text-black': !!props.value,
            '!text-gray-400': !props.value,
          },
        )}
        disabled={props.disabled}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onChange={(e) => {
          if (!props.onChange) {
            return;
          }

          if (!e.target.value) {
            return props.onChange(null);
          }

          const option = options.find((o) => o.value.toString() === e.target.value);

          props.onChange(option?.value || null);
        }}
      >
        {!options.some((o) => !o.value) && (
          <option value="" disabled hidden={!!props.value}>
            {props.placeholder || 'Please choose an option.'}
          </option>
        )}
        {options.map((o) => (
          <option key={o.value} value={o.value} disabled={o.disabled}>
            {o.label}
          </option>
        ))}
      </select>
      {props.name && <input name={props.name} type="hidden" value={props.value ?? ''} />}
    </>
  );
}
